let mode = process.env.NODE_ENV;

let isProd = mode === "production";

let URL;


if (isProd) {
    let server = process.env.VUE_APP_SERVER || "https://api.ex-bank.cc";
    URL = {
        main: "http://localhost:8080/",
        api: `${server}/api/`,
        server: server,
        client: process.env.VUE_APP_DOMAIN || "https://ex-bank.cc/"
    };
} else {
    URL = {
        main: "http://localhost:8080/",
        api: "http://localhost:3000/api/",
        server: "http://localhost:3000",
        client: "https://ex-bank.cc/"
    };
}

export { URL };
