<template lang="">
    <div v-if="exchange" class="noVerifyExchangeSection">
        <p v-html="noVerificationText"></p>
        <div class="noVerifyExchangeSection_offerText">
            <p>
                {{ $t("popupCard.verifyCard.vmnwAgree") }}
                <span class="noVerifyExchangeSection_btn" @click="createHandler">
                    {{ spinner ? $t("popupCard.verifyCard.creatingVmnvBtn") : $t("popupCard.verifyCard.createVmnvBtn") }}
                </span>
            </p>
        </div>
    </div>
</template>
<script>
import getExchangeAmount from "../../../utils/getExchangeAmount";

// Servicespinner
import ExchangeService from "../../../services/ExchangeService";
import OrderService from "../../../services/OrderService";


export default {
    data() {
        return {
            exchange: null,
            spinner: false
        };
    },
    mounted() {
        this.fetchNoVerifyExchange();
    },
    props: {
        orderData: Object,
        orderFieldsData: Object,
        successCreateHandler: Function,
        errorCreateHandler: Function
    },
    destroyed() {
        let exchangeId = this?.exchange?._id;
        if (exchangeId) {
            this.sockets.unsubscribe(`update_exchange_${exchangeId}`);
        }
        this.exchange = null;
        this.spinner = false;
    },
    components: {
        // MyButton
    },
    methods: {
        getExchangeAmount,
        async fetchNoVerifyExchange() {
            let excId = this.orderFieldsData.exchange;

            let response = await ExchangeService.getOne({ id: excId, uniqueType: "vmnv" });
            let exchange = response.data;

            if (!exchange) return;

            this.exchange = exchange;

            this.sockets.subscribe(`update_exchange_${exchange._id}`, e => {
                if (e._id == exchange._id) {
                    let updated = Object.assign({}, e);
                    updated.currency.take.name = this.exchange?.currency?.take?.name;
                    updated.currency.give.name = this.exchange?.currency?.give?.name;
                    this.exchange = updated;
                }
            });
        },
        async createHandler() {
            try {
                if (this.spiner) return;

                let exchange = this.exchange;

                this.spiner = true;

                let data = this.orderFieldsData;

                data = Object.assign({}, data, {
                    take: this.noVerificationRate.take,
                    give: this.noVerificationRate.give,
                    exchange: exchange._id
                });

                let response = await OrderService.create(data);
                this.successCreateHandler(response.data);
            } catch (e) {
                console.error(e);
                this.errorCreateHandler(e);
            } finally {
                this.spiner = false;
            }
        }
    },
    computed: {
        noVerificationRate() {
            let exchange = this.exchange;

            let amount = this.orderFieldsData.take;

            let sum = this.getExchangeAmount(exchange, "give", +amount);

            return {
                take: amount,
                give: sum
            };
        },
        noVerificationRateText() {
            let exchange = this.exchange;
            return `${this.noVerificationRate.take} ${exchange.currency.take.code} => ${this.noVerificationRate.give} ${exchange.currency.give.code}`;
        },
        noVerificationText() {
            let exchange = this.exchange;
            return this.$t("popupCard.verifyCard.wmnvText", { takeName: exchange.currency.take.name, giveName: exchange.currency.give.name, rate: this.noVerificationRateText });
        }
    }
};
</script>
<style lang="scss" scoped>
.noVerifyExchangeSection {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ff0000d9;
    margin: 10px 0px;
}

.noVerifyExchangeSection_offerText {
    // color: blue;
    margin-top: 10px;
}

.noVerifyExchangeSection_btn {
    color: #3597fa;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 5px;
}
.noVerifyExchangeSection_btn:hover {
    text-decoration: underline;
}
</style>
