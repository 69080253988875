<template lang="">
    <div class="verifyCardSection">
        <ModalContentWrap :headerName="$t('popupCard.verifyCard.title')" :closeHandler="closeHandler">
            <div>
                <StepItem :number="1" :text="$t('popupCard.verifyCard.step1')" />

                <div class="verifyCardSection_description">
                    {{ $t("popupCard.verifyCard.desc1", { number: '' }) }}
                </div>

                <div class="verifyCardSection_descriptionImport">
                    {{ $t("popupCard.verifyCard.desc2") }}<br />
                    {{ $t("popupCard.verifyCard.desc3") }}

                    <br />
                    {{ $t("popupCard.verifyCard.desc4") }}
                </div>
                <CardPhotoExample v-if="!image" />
                <NoVerifyExchange :orderFieldsData="orderFieldsData" :orderData="orderData" :successCreateHandler="successCreateHandler" :errorCreateHandler="errorCreateHandler" />

                <StepItem :number="2" :text="addImageText" />
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
            </div>
            <template v-slot:btns>
                <div class="verifyCardSection_btnsWrap">
                    <my-button class="outline" :outline="true" :value="$t('popupCard.verifyCard.confirm.cancel')" @click.native="closeHandler"></my-button>
                    <my-button :value="$t('popupCard.verifyCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner"></my-button>
                </div>
            </template>
        </ModalContentWrap>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
// Components
import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
// Computeds
import addImageText from "./computeds/addImageText";
import isScreenMobile from "./computeds/isScreenMobile";

// Modules
import NoVerifyExchange from "./modules/NoVerifyExchange.vue";

// UI
import StepItem from "../ui/StepItem.vue";
// import MyInput from "../ui/input";
import MyButton from "../ui/button.vue";

// Services
import OrderService from "../../services/OrderService";

// Main Ui
import CardPhotoExample from "./UI/CardPhotoExample.vue";

export default {
    data() {
        return {
            image: null,
            file: null,
            spinner: false,
            exchange: null
        };
    },
    props: {
        closeHandler: Function,
        // card: String || Object,
        orderData: Object,
        successCreateHandler: Function,
        errorCreateHandler: Function,
        orderFieldsData: Object
    },
    methods: {
        ...mapMutations(["USER_LOGIN", "SET_ORDER_TOKEN", "SET_ORDER_DATA", "SET_CURRENT_ORDER", "SET_SNACK_BAR"]),
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        },
        async sendVerifyHandler() {
            try {
                // console.log(this.file, 'file')
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;
                let form = new FormData();
                form.append("data", JSON.stringify(this.orderFieldsData));
                form.append("dest", "card");
                form.append("image", this.file);
                let response = await OrderService.create(form);
                this.successCreateHandler(response.data);
            } catch (e) {
                this.errorCreateHandler(e);
            } finally {
                this.spinner = false;
            }
        }
    },
    computed: {
        addImageText,
        isScreenMobile
    },

    components: {
        ImageSelect,
        ModalContentWrap,
        StepItem,
        NoVerifyExchange,
        CardPhotoExample,
        MyButton
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    }
};
</script>
<style lang="scss" scoped>
.verifyn {
}

.verifyCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.verifyCardSection_descriptionImport {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}

.verifyCardSection_description > p {
    margin-bottom: 16px;
}
.verifyCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.verifyCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.verifyCardSection_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
</style>
